@import 'global';
@import 'variables';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

//LAYOUT START ---------------------------------------------

//DESKTOP
.container {
    overflow: hidden;   
}

main { 
    margin-top: 8rem;
    height: 100vh;
}

//MEDIA QUERY FOR MOBILE/TABLET LAYOUT
@media only screen and (max-width: 1024px) {   

    main {
        margin-top: 5rem;
        height: 55vh;
    }   
}

//LAYOUT END -----------------------------------------------

//Container
.parent {
    // height: 400vh;
    background: radial-gradient(circle at center, rgba(var(--color1), 1), rgba(var(--color2), 1), rgba(var(--color1), 1), rgba(var(--color2), 1));
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;

    @keyframes gradient {
        0% {
            background-position: center;
        }
        50% {
            background-position: 100% 100%;
        }
        100% {
            background-position: center;
        }
    }
}
.container {
    // height: 400vh;
    background-color: rgba(var(--color1), 0.65);
}


.github-symbol {
    margin-right: .5rem;
}

.menu-symbol {
    font-size: 3rem;
    color: rgba(var(--color2), 1);
    opacity: .9;    
    padding: .4rem .2rem;              
}



// MAIN
main {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
}

#name-container {
    position: relative;
    height: 100%;
    width: 100%;
    font-family: $font2;    
    color: rgba(255, 255, 255, 0.8);

    h2 {
        -webkit-text-stroke: 5px rgba(var(--color1), 1);
        font-size: 10vw;
        position: absolute;
        font-weight: normal;
        font-style: normal;
        width: 100%;
        z-index: 100;
        transform: translateX(10px);
        opacity: 0;
        animation: text-reveal 2s forwards 1s;
        transition: -webkit-text-stroke 1s;

        &:first-child {
           top: 0;     
           text-align: left;  
           padding-left: 3.5vw;
        }

        &:nth-child(2) {
            top: 18vh;
            text-align: right;
            padding-right: 14vw;
        }
    }

    h3 {
        -webkit-text-stroke: 3px rgba(var(--color1), 1);
        font-size: 5vw;
        position: absolute;
        font-weight: normal;
        font-style: normal;
        width: 100%;
        text-align: center;
        top: 42vh;
        padding-right: 15vw;
        z-index: 100;
        transform: translateY(10px);
        opacity: 0;
        animation: text-reveal 2s forwards 2s;
        transition: -webkit-text-stroke 1s;
    }

    @keyframes text-reveal {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1; 
        }
    }

    .circle {
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;         
        position: absolute;               
        border-radius: 30%;
        z-index: 50;
        backdrop-filter: blur(15px);
        transition: background 2s;
        outline: 1px solid rgba(255, 255, 255, 0.5);

        &::before {
            content: '';
            position: absolute;
            background: url(../images/noise.svg) repeat;
            opacity: 0.075;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 30%;
        }

        &:nth-of-type(1) {
            background: linear-gradient(0.45turn,rgba(0,0,0,0), rgba(var(--color2), 1));
            height: 21vw;
            width: 21vw;
            top: 0;
            left: 15vw;
            animation: circle-up-down 3s ease-in-out infinite;
            animation-delay: 2s;
        }

        &:nth-of-type(2) {
            background: linear-gradient(0.45turn,rgba(0,0,0,0), rgba(var(--color3), 1));
            height: 35vw;
            width: 35vw;
            top: 5rem;
            left: 28vw;
            animation: circle-up-down 2.5s ease-in-out infinite;
            animation-delay: 2s;
        }

        @keyframes circle-up-down {
            50% {
                transform: translateY(-4vh) rotate(10deg);
            }
        }

    }
}

//Services
.services {
    position: relative;
    // background-color: rgba(var(--color1), 0.35);
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0 1rem;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    overflow: hidden;

    &::before {
        content: '';
        background: url(../images/noise.svg);
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 15px;
        opacity: 0.1;
        z-index: -1;
    }

    h4 {
        height: 60px;
        font-size: 30px;  
        padding: 10px;
        margin: 0 2vw 1rem 0;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        border-bottom-right-radius: 15px;
        font-family: $font4;      
        font-weight: lighter;
        color: rgba(255, 255, 255, 0.75);
        background-color: rgba(0, 0, 0, 0.2);
        display: inline-block;
        box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    }  

    .services-container {
        padding: 0 2vw 2vh 0;
        font-family: $font3;
        text-align: center;

        span {
            font-size: 3vw;            
            display: inline-block; 
            font-style: normal;
            font-weight: normal;         
            margin-left: 2vw;
            word-spacing: -1rem;
            color: rgba(var(--color3), 0.85);

            &:first-child,
            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(8),
            &:nth-child(10) {
                color: rgba(var(--color2), 0.85);
            }
        }

    }

}

//MEDIA QUERY FOR MOBILE ITEMS -----------------------------------
@media only screen and (max-width: 1024px) {    
    //MAIN
    main {
        display: block;
    }

    #name-container {
        h2 {            
            -webkit-text-stroke: 2px rgba(var(--color1), 1);          
    
            &:first-child {
               text-align: left;              
            }
    
            &:nth-child(2) {                
                top: 10vh;
                text-align: right;                
            }
        }
    
        h3 {            
            -webkit-text-stroke: 1.5px rgba(var(--color1), 1);
            top: 23vh;
        }
    
        .circle {     
                
            &:nth-of-type(1) {
                top: -5vh;                
                width: 28vw;
                height: 28vw;
            }
    
            &:nth-of-type(2) {
                top: 4vh;                
                width: 45vw;
                height: 45vw;
            }
    
    
        }
    }

    //Services
    .services {
        .services-container {
            span {
                word-spacing: -.5rem;                
            }
        }
    }
}