//Fonts
@import url(https://fonts.googleapis.com/css2?family=Racing+Sans+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);


//Colors
:root {
    // (Default Theme)
    --color1: 44, 62, 80;
    --color2: 231, 76, 60;
    --color3: 52, 152, 219;
    --color4: 27, 38, 49;
    --color5: 211, 84, 0;
}

//Fonts
$font1: 'Racing Sans One', serif;
$font2: 'Righteous', cursive;
$font3: 'Rubik Mono One', sans-serif;
$font4: 'Sora', sans-serif;