@import 'variables';

// NAVBAR LAYOUT
nav {
    position: fixed;    
    width: 100%;
    height: 8rem;
    z-index: 150;    
    transition: height .75s;
}

//MEDIA QUERY FOR MOBILE/TABLET LAYOUT
@media only screen and (max-width: 1024px) {

    nav {
        height: 5rem;
    }

    .nav-links a {
        display: none;
    }

    .nav-menu-button {
        display: block;
    }
}

//NAVBAR DESIGN
// NAV
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font4;

    h1 {
        left: 15px;
        font-family: $font2;
        color: white;
        width: 50%;
        text-align: center;
        text-transform: uppercase;
        -webkit-text-stroke: 1px rgba(var(--color1), 1); 
        border-radius: 35px;
        font-weight: normal;
        font-size: 2.5vh;
        position: relative;
        background: rgba(var(--color1), 0.65);
        backdrop-filter: blur(15px);
        overflow: hidden;
        outline: 1px solid rgba(255, 255, 255, 0.4);
        box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

        .h1-circle {                                  
            position: absolute;            
            border-radius: 30%;
            margin-left: 45%;
            visibility: none;
                 
            &:nth-child(2) {
                background: linear-gradient(0.45turn,rgba(0,0,0,0), rgba(var(--color3), 1));
                height: 4rem;            
                width: 4rem;                 
                top: 1px;
                left: -40px;
                
                animation: circle-up-down 2.5s ease-in-out infinite;
            }

            &:first-child {
                background: linear-gradient(0.45turn,rgba(0,0,0,0), rgba(var(--color2), 1));
                height: 7rem;            
                width: 7rem;                 
                top: -3rem;
                left: 10px;

                animation: circle-up-down 3s ease-in-out infinite;
            
            }   
            
            @keyframes circle-up-down {
                50% {
                    transform: translateY(-4vh) rotate(10deg);
                }
            }
        }

        span {
            transition: margin .25s;            
            position: relative;
        }

        &:hover span {
            margin: 0 !important;
        }
    }

    .nav-links {
        display: flex;
        width: 50%;
        padding-right: 10%;
        gap: 45px;
        height: 100%;
        justify-content: flex-end;
    
        a {
            top: -30px;
            left: -20px;
            opacity: 0;
            text-decoration: none;
            padding: .4rem;
            color: rgba(var(--color1), 1);
            font-size: 20px;
            position: relative;
            transition: 0.2s;
            
            &::before {
                content: '';
                position: absolute;
                top: -30px;
                left: -10px;
                right: -35px;
                bottom: -10px;
                background: rgba(var(--color3), 0.55);
                backdrop-filter: blur(10px);
                transform: skew(35deg, 0deg);
                z-index: -1;
                transition: transform 0.2s, background-color 1s, border-radius 0.15s;
                // border-bottom-left-radius: 20px;
                // border-bottom-right-radius: 25px;
                box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
                border: 1px solid white;
            }

            &:first-child {
                &::before {
                    border-bottom-left-radius: 20px;
                }
            }

            &:last-child {
                &::before {
                    border-bottom-right-radius: 25px;
                }
            }
    
            span {
                height: 100%;
                display: inline-block;
                position: relative;
                top: 50%;
                left: 32%;
                transition: transform 0.2s, background-color 1s;
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
            }
    
            &:hover {
                &:before {
                    transform: skew(35deg, 0deg) translateY(25px);
                }
                span {
                    transform: translateY(15px) translateX(10px);
                }
            }
    
            &:active {
                &:before {
                    transform: skew(35deg, 0deg) translateY(20px);
                }

                span {
                    transform: translateY(20px) translateX(15px);
                }
            }
        }
    }
}


.nav-menu-button {
    display: none;  
    position: fixed;
    top: 1rem;
    right: 2rem;
    z-index: 350;
}

//Full Page Menu
.nav-menu-button-inner {
    position: absolute;
    top: 1rem;
    right: 2rem;
}

//FULL MENU WRAPPER
.full-menu-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: .5s;  
    
    //Aligning link items
    display: flex;    
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    padding: 10rem 0;    

    a {
        color: white;
        text-decoration: none;
        font-family: $font1;         
        text-align: center;   
        font-size: 3rem;
        transition: .1s;

        &:hover {
            color: rgba(var(--color3), 1);
        }
    }
}

//MEDIA QUERY FOR MOBILE ITEMS 
@media only screen and (max-width: 1024px) {
    //NAV
    .nav-links a {
        display: none;
    }

    .nav-menu-button {
        display: inline-block;           
    }

    .nav-links {
        justify-content: flex-end;
    }
}