@import 'variables';

#projects {
    height: 80vh;
    min-height: 80vh;
    backdrop-filter: blur(10px);
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 1rem 1rem 2rem 1rem;
    // margin: 1rem 0;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    overflow: hidden;

    &::before {
        content: '';
        background: url(../images/noise.svg);
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 15px;
        opacity: 0.1;
    }

    h4 {
        height: 60px;
        font-size: 30px;  
        padding: 10px;
        margin: 0 2vw 1rem 0;
        border-right: 1px solid rgba(255, 255, 255, 0.4);
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        border-bottom-right-radius: 15px;
        font-family: "Sora", sans-serif;
        font-weight: lighter;
        color: rgba(255, 255, 255, 0.75);
        background-color: rgba(0, 0, 0, 0.2);
        display: inline-block;
        box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    }

    #projects-slides {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 60px);

        div.project-slide {
            position: absolute;
            background-color: rgba(var(--color1), 0.5);
            backdrop-filter: blur(10px);
            color: white;
            border: 1px solid rgba(255, 255, 255, 0.4);
            border-radius: 20px;
            padding: 10px;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            margin: 20px;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px, rgba(var(--color2), 0.15) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
            font-family: $font1;

            .project-name {
                display: inline-block;
                margin-top: 10px;
                margin-left: 10px;
                font-size: 29px;
                background: rgba(var(--color4), 0.3);
                padding: 2px 15px;
                border-radius: 30px;
                color: rgba(255, 255, 255, 0.9);
                border: 1px solid rgba(255, 255, 255, 0.4);

                span.project-icon {
                    font-size: 20px;
                    height: 100%;
                    display: inline-flex;
                    margin-right: 8px;

                    svg {
                        opacity: 0.5;
                        animation: rotate 8s infinite linear;
                        @keyframes rotate {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                        }
                    }
                }
            }

            .project-content {
                display: flex;
                height: 100%;

                &-image {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

                    img {
                        background-color: rgba(255, 255, 255, 0.75);
                        max-height: 400px;
                        max-width: 100%;
                        border-radius: 40px;
                        opacity: 0.75;
                    }
                }

                &-description {
                    font-family: $font4;
                    width: 50%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 20px;

                    &-details {
                        display: inline-block;
                        background-color: rgba(0, 0, 0, 0.4);
                        width: 100%;
                        border-radius: 20px;
                        padding: 30px 20px 30px 50px;
                        box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px, rgba(var(--color2), 0.1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                        border: 1px solid rgba(255, 255, 255, 0.4);

                        ul {
                            li {
                                margin-bottom: 20px;

                            }
                        }
                    }
                }
            }
        }
    }
}